<!-- splash screen -->
<div #splashScreen id="splash-screen">
  <h2>
     SEABORN SOFTWARE LTD
  </h2>
  <!--  <img src="./assets/media/logos/izmeer-logo.png" alt="Logo" />  -->
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg>
</div>

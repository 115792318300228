import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
// import { UsersTable } from 'src/app/_fake/fake-db/users.table';
import {AllCompanies, CompanyRegister, Department, PasswordResetModel, Users, Vessel} from 'src/app/pages/models/NewRecordModel';
import {JwtHelperService} from '@auth0/angular-jwt';
import {catchError, tap} from 'rxjs/operators';
import {ApiAddress} from '../../../../pages/models/api-address';
import {Router} from '@angular/router';

const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  // tslint:disable-next-line:variable-name
  api_url = new ApiAddress();
  decodedToken: any;
  jwtHelper = new JwtHelperService();
  constructor(private http: HttpClient,  private router: Router) {

  }

  // public methods

  login(email: string, password: string): Observable<any> {
    // console.log(email, password);
    return this.http.post<any>(this.api_url.address + 'Identity/login/', { email, password });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    console.log(email);
    return this.http.post<boolean>(this.api_url.address + 'Identity/SendMailForResetPassword?userMail=' + email, {});
  }

  resetPassword(model: PasswordResetModel): Observable<boolean> {
    return this.http.post<boolean>(this.api_url.address + 'Identity/ResetPassword' , model);
  }

  getUserByToken(token: string): Observable<UserModel> {
    this.decodedToken = this.jwtHelper.decodeToken(JSON.stringify(token));
    // console.log(this.decodedToken);
    const user = this.decodedToken;
    if (!user) {
      return of(undefined);
    }
    return of(user);
  }

  loginRefreshToken(){
    const tokenPayload = {
      refreshToken: localStorage.getItem('refreshToken'),
      token: localStorage.getItem('token')
    };
    return this.http.post<any>(this.api_url.address + 'Identity/refreshToken', tokenPayload).pipe(
        tap((tokens: any) => {
      this.storeJwtToken(tokens);
    },
            err => {
            localStorage.removeItem('tokenAllInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('companyId');
            localStorage.removeItem('role');
            this.router.navigate(['/auth/login'], {
              queryParams: {},
            });
            console.log(err);
            }));
  }

  private storeJwtToken(jwt) {
    localStorage.setItem('token', jwt.token);
    localStorage.setItem('refreshToken', jwt.refreshToken);
  }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class ErrorInterceptor implements HttpInterceptor{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req)
        .pipe(catchError((error: HttpErrorResponse) => {
            if (error.status === 400){
                if (error.error.errors){
                    const serverError = error.error;
                   // console.log(serverError.errors);
                    let errorMessage = '';
                    // tslint:disable-next-line:forin
                    for (const key in serverError.errors){
                        errorMessage += serverError.errors[key] + '\n';

                    }
                   // console.log(errorMessage);
                    return throwError(errorMessage);

                }else{
                    return throwError(error.error);
                }

            }

            if (error.status === 500){
                if (error.error != null){
                return throwError(error.error.message);
                }
                else{
                    return throwError(error.statusText);
                }
            }
            if (error.status === 406){
                return throwError(error.error);
            }
            if (error.status === 403){
                return throwError(error.error);
            }
            if (error.status === 404){
                return throwError(error.statusText);
            }
            if (error.status === 201){
              alert();
            }

            if (error.status === 401){
                return throwError(error.statusText);
              }
        }));




    }

}

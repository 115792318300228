import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from './_services/auth.service';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {AuthHTTPService} from './_services/auth-http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isRefreshing = false;

    constructor(private authService: AuthService, private  auth: AuthHTTPService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.getToken()){
      request = this.addToken(request, this.authService.getToken());
    }
    return next.handle(request).pipe(catchError(error => {
        if ( error.status === 401) {
            return this.handle401Error(request, next);
        } else {
            return throwError(error);
        }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {

      return request.clone({
      setHeaders: {
        Authorization: `bearer ${token}`
      }
    });
  }
  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //     alert('handle  a girdi');
  //    // return this.authService.refreshToken();
  //     if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);
  //     alert('refresh token test');
  //     return this.auth.loginRefreshToken().subscribe(res => {
  //                 console.log(res);
  //                // alert('refresh dondu');
  //                 this.storeJwtToken(res);
  //               //  alert('refresh token apiden döndü');
  //                 this.isRefreshing = false;
  //                 this.refreshTokenSubject.next(res.token);
  //                 return next.handle(this.addToken(request, res.token));
  //             },
  //             error => {
  //                 alert('888');
  //                 console.log(error);
  //
  //             });
  //         // .pipe(
  //         // switchMap((token: any) => {
  //         //   alert('refresh token apiden döndü');
  //         //   this.isRefreshing = false;
  //         //   this.refreshTokenSubject.next(token.token);
  //         //   return next.handle(this.addToken(request, token.token));
  //         // }));
  //
  //   }
  //     else {
  //         alert('apiye gitmedi');
  //         return this.refreshTokenSubject.pipe(
  //         filter(token => token != null),
  //         take(1),
  //         switchMap(jwt => {
  //             alert('jwt giden yer');
  //             return next.handle(this.addToken(request, jwt));
  //         }));
  //   }
  // }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.auth.loginRefreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token.token);
                    return next.handle(this.addToken(request, token.token));
                }));
        }
        else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    console.log(jwt);
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }
    private storeJwtToken(jwt) {
        localStorage.setItem('token', jwt.token);
        localStorage.setItem('refreshToken', jwt.refreshToken);
    }
}
